import React, {Component} from "react"
import {graphql} from "gatsby"
import PropTypes from "prop-types"
import SEO from "../components/seo";
import Layout from "../components/layout"
import ProductCell from "../components/product/productCell"
import RelatedStories from "../components/news/related_stories"

class PostTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: props.data.wordpressPost,
      author: {}
    }
  }

  componentDidMount = () => {
    let author = this.state.post.author
    let _this = this;
    return fetch(`https://${process.env.WP_DOMAIN}/wp-json/wp/v2/users/${author}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(function(response) {
      return response.json()
    }).then(function(parsedResponse) {
      console.log('parsedresponse: ', parsedResponse)

      if (parsedResponse.error) {
        throw parsedResponse.error.message
      }

      _this.setState({author: parsedResponse})
    }).catch(function(error) {
      console.log(error)
    })
  }
  render() {
    const data = this.props.data

    const stories = data.allWordpressPost.edges.filter(function(e) {
      return e.node.categories[0].name === "News"
    });
    const products = this.props.data.allWcProducts.edges

    const helmetMeta = [
      {
        name: `title`,
        content:this.state.post.yoast_meta.yoast_wpseo_title,
      },
      {
        name: `keywords`,
        content: this.state.post.yoast_meta.yoast_wpseo_focuskw,
      }
    ]
    return (
      <Layout isAlternate={true}>
        { this.state.post.yoast_meta.yoast_wpseo_title !== ""?
          <SEO title={this.state.post.yoast_meta.yoast_wpseo_title} meta={helmetMeta}/>
          :
          false
        }
        <section className="hero has-yellow-background has-absolute-navbar">
          <div className="hero-body">

            <div className="container">
              <div className="columns is-centered is-vcentered">
                <div className="column is-5">
                  <p className="has-text-danger has-text is-uppercase has-text-weight-bold">{this.state.post.categories[1].name !== "News" ? this.state.post.categories[1].name : this.state.post.categories[0].name}</p>
                  <br/>
                  <h1 className="has-text-white">
                    {this.state.post.title}
                  </h1>
                  <div className="has-text-white is-size-3" dangerouslySetInnerHTML={{
                    __html: this.state.post.excerpt
                  }}/>
                  <br/>
                  <p className="has-text-white">
                    {this.state.post.acf.time_to_read} read
                  </p>
                </div>
                <div className="column is-offset-1">
                  {this.state.post.featured_media.source_url && <img src={this.state.post.featured_media.source_url} alt={this.state.post.featured_media.alt_text}/>}
                </div>

              </div>

            </div>
          </div>

        </section>
        <section className="section is-medium has-absolute-navbar">

          <div className="container">
            <div className="columns">
              <div className="column is-6">

                <div className="article-content" dangerouslySetInnerHTML={{
                  __html: this.state.post.content
                }}/>
                <hr className="has-black-background"/>

                <article className="media">
                  <figure className="media-left">
                    <p className="image is-64x64">
                      {(this.state.author.avatar_urls && Object.keys(this.state.author.avatar_urls).length > 0) && <img alt="" className="is-rounded" src={this.state.author.avatar_urls[Object.keys(this.state.author.avatar_urls)[Object.keys(this.state.author.avatar_urls).length - 1]]}/>
}
                    </p>
                  </figure>
                  <div className="media-content is-vertical-center">
                    <div className="content">
                      <p className="has-text-weight-bold">{this.state.author.name}</p>
                      <p>{this.state.author.description}</p>
                    </div>
                  </div>
                </article>
              </div>

            </div>

          </div>

        </section>
        <section className="section">
          <div className="container">
          <h2 className="has-text-centered">You may also like</h2>
          <br/>

                <RelatedStories stories={stories}/>
                    </div>

                </section>
                <section className="section">
                  <div className="container">
                  <h1 className="has-text-centered">Here's your<br/>Reason to Smile</h1>
                  <br/>
                    <div className="columns is-multiline is-centered is-mobile">

                      {products.map(({node}, index) => (
                        <ProductCell product={node} key={index}/>
                      ))}
                    </div>

                  </div>

                </section>
      </Layout>
    )
  }
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array
}

export default PostTemplate

export const pageQuery = graphql `
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      title
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_focuskw
        yoast_wpseo_metadesc
      }
      content
      excerpt
      path
      status
      template
      format
      author
      acf {
        time_to_read
      }
      featured_media {
        id
        source_url
        alt_text
      }
      categories {
        id
        name
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allWordpressPost {
      edges {
        node {
          title
          excerpt
          slug
          path
          featured_media {
              id
              source_url
            }
          categories {
            name
          }
          acf {
            time_to_read
          }
        }
      }
    }
    allWcProducts(filter: {catalog_visibility: {eq: "visible"}, status: {eq: "publish"}, tags: {elemMatch: {name: {eq: "CBD"}}}}, limit: 4) {
      edges {
        node {
          id
          type
          slug
          name
          price
          categories {
            wordpress_id
            name
          }
          acf {
            flavor
            effect
            product_type
          }
          images {
            id
            alt
            src
          }
          grouped_products_nodes {
            id
            wordpress_id
            type
            price
          }
        }
      }
    }
  }
`
