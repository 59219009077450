import React from "react"
import {Link} from "gatsby"

const RelatedStories = (props) => {
  const stories = props.stories
  return (
    <div className="columns is-centered">
    {stories.map(({node}, index) => (
      <div className="column is-4" key={index}>
      <Link to={`/news${node.path}`} css={{
        textDecoration: `none`
      }}>



            {node.featured_media && <img src={node.featured_media.source_url} alt=""/>
}

            <p className="has-text-danger has-text is-uppercase has-text-weight-bold">{node.categories[1].name}</p>

            <h3 className="has-text-black" dangerouslySetInnerHTML={{
              __html: node.title
            }}/>
            <div className="has-text-grey-dark is-size-4" dangerouslySetInnerHTML={{
              __html: node.excerpt
            }}/>
            <p className="has-text-grey-dark">{node.acf.time_to_read} read</p>

      </Link>
      </div>

    ))}
    </div>
  )

}

export default RelatedStories
